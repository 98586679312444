import styled from 'styled-components'
import Typography from '@eig-builder/core-ui/Typography'

const ToSContainer = styled.div`
  width: 100%;
  margin-top: 75px;
  padding-bottom: 40px;
`

ToSContainer.alert = styled.div`
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
  font-size: 18px;
  align-self: center;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 2px;
  margin-bottom: 40px;
`

ToSContainer.header = styled.div`
  align-self: center;
`

ToSContainer.link = styled.a`
  color: #2196f3;
  text-decoration: none;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
`

ToSContainer.notice = styled(Typography).attrs({
  variant: 'h6',
  gutterBottom: true
})`
  font-style: italic;
  font-size: 16px !important;
  font-weight: 700;
  font-size: 1.1em;
`

ToSContainer.arbitration = styled(Typography).attrs({
  variant: 'body1',
  gutterBottom: true
})`
  font-style: italic;
  font-size: 16px !important;
  font-weight: 700;
  font-size: 1.1em;
`

ToSContainer.quickLinks = styled.div`
  align-self: center;
  margin-top: 50px;
`

ToSContainer.content = styled.div`
  align-self: center;
  border-top: 1px solid ${({ theme }) => theme.palette.black.lighter};
  margin-top: 40px;
  padding-top: 5px;
`

ToSContainer.bold = styled.span`
  font-weight: 700;
  font-size: 1.1em;
`

ToSContainer.anchor = styled.span`
  display: block;
  height: 82px;
  margin-top: -82px;
  visibility: hidden;
`

export default ToSContainer
