import React from 'react'

import Layout from '../../components/layout'
import TermsOfServiceComponent from '../../components/terms-of-service'
import LayoutTerm from '../../components/layout/terms-layout'
import SEO from '../../components/seo'

const IndexPage = () => {
  return (
    <Layout isWhiteHeader paddingTop>
      <SEO
        title='Terms of Service'
        keywords={[
          `web hosting`,
          `domain names`,
          `web site`,
          `search engine optimization`,
          `hosting`,
          `servers`
        ]}
        pageType={'company_info'}
        flowType={'none'}
      />
      <LayoutTerm>
        <TermsOfServiceComponent />
      </LayoutTerm>
    </Layout>
  )
}

export default IndexPage
